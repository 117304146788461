import React from 'react';
import Square from './Square'

// urilisation de PureComponent à la place de component => ne relance pas le render si les props sont identiques
// class Board extends React.PureComponent {
  const Board = props => {


    const renderSquare = (i) => {
      // console.log('render Square');
      // console.log(props);
      
      
    return (
      <Square
        
        key={props.squares[i].valeur}
        valeur={props.squares[i].valeur}
        value={props.squares[i].valeur}
         u_r={props.squares[i].u_r}
         u_l={props.squares[i].u_l}
         u_t={props.squares[i].u_t}
         u_b={props.squares[i].u_b}
         w_r={props.squares[i].w_r}
         w_l={props.squares[i].w_l}
         w_t={props.squares[i].w_t}
         w_b={props.squares[i].w_b}
         fa={props.squares[i].fa}
         caracts={props.squares[i].caracts}
         visited={props.squares[i].visited}
      />
    );
  }


    const renderContentRow = (n) => {
      let td = [];
      for (let i = 1; i <= props.horizontal_square; i++) {
        // console.log('loop render Board');
        td.push(
          renderSquare(i - 1 + n * props.horizontal_square));
      }
      return td;
    };

    const renderRow = () => {

if(props.squares.length !== 0){


      let td = [];
      for (let i = 1; i <= props.vertical_square; i++) {
        
        let row_wall = "";
        if (i === 1) {
          row_wall = "row_wall_top ";
        }
        else if (i === props.vertical_square) {
          row_wall = "row_wall_bottom ";
        }
        td.push(<div className={row_wall + 'board-row'} key={i}>
          {renderContentRow(i - 1)}
        </div>);
      }
      return td;
    }

    };
    // console.log('render Board');
    return (
      <div>
        {renderRow()}
      </div>
    );

}

//export default Board;
export default React.memo(Board);