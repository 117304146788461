import React from 'react';
import Newgame from './Newgame'


const PopupFinish = (props) => {



  if (props.displayPopupFinish === true) {
  return (
        <div className="menu">
          <div className="menucontente">
          <p className="menutitre">C'est la fin</p>
          <Newgame
                      displayNewgame={true}
                      restartgame={props.restartgame}
                      setDisplayMenu={props.setDisplayPopupFinish}
                    />
          </div>
        </div>
      );
  }
}


export default React.memo(PopupFinish);
  // export default Menu;