// exemple de function component
// import react non obligatoire mais ici on a besoin de la méthode memo => création d'un PureComponement sur une function component
import React from 'react';
// import logob from '../chara/chara_b0.png'
// import logot from '../chara/chara_t0.png'
// import logor from '../chara/chara_r0.png'
// import logol from '../chara/chara_l0.png'

const Player = (props) => {

  let logo = "logob";

  switch (props.direction) {
    case 'ArrowUp':
      logo = "logot";
      break;
    case 'ArrowRight':
      logo = "logor";
      break;
    case 'ArrowLeft':
      logo = "logol";
      break;
    default:
      logo = "logob";
  }

    return (
      // <img src={logo} alt="Logo" className="player"/>
        <div className={logo+props.classlogo + " player"} >
        </div>
      );

}


  // export default React.memo(Player);
  export default Player;