
import React from 'react';

const Newgame = (props) => {




  if (props.displayNewgame === true) {

  return (
        <div className="btnnewgame menubtn">
          <div onClick={()=>{
            props.restartgame();
            props.setDisplayMenu(false);
            }}>Nouvelle partie</div>
        </div>
      );
    }
    else{
      return null;
    }
}


export default React.memo(Newgame);
  // export default Newgame;