import React from 'react';



const MobilControl = (props) => {



  // if (props.displayPopupFinish === true) {
  return (
        <div className="mobilControl">

          <svg xmlns="http://www.w3.org/2000/svg" version="1.1" className="arrow-up" width='100vw' height='50vh' preserveAspectRatio="none" viewBox="0 0 100 100" >
    <polygon points="0,0 100,0 50,100" onClick={() => { props.setDataMobilControl("ArrowUp") }}></polygon>
  </svg>



  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" className="arrow-down" width='100vw' height='50vh' preserveAspectRatio="none" viewBox="0 0 100 100" >
    <polygon points="50,0 0,100 100,100" onClick={() => { props.setDataMobilControl("ArrowDown") }}></polygon>
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" className="arrow-left" width='50vw' height='100vh' preserveAspectRatio="none" viewBox="0 0 100 100" >
    <polygon points="0,0 0,100 100,50" onClick={() => { props.setDataMobilControl("ArrowLeft") }}></polygon>
  </svg>
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" className="arrow-right" width='50vw' height='100vh' preserveAspectRatio="none" viewBox="0 0 100 100" >
    <polygon points="100,0 0,50 100,100" onClick={() => { props.setDataMobilControl("ArrowRight") }}></polygon>
  </svg>
  

          {/* <div className="arrow-up">
          <div className="arrow-up-link" onClick={() => { props.setDataMobilControl("up") }}></div>
          </div> */}
          {/* <div className="arrow-down" onClick={() => { props.setDataMobilControl("down") }}></div>
          <div className="arrow-right" onClick={() => { props.setDataMobilControl("right") }}></div>
          <div className="arrow-left" onClick={() => { props.setDataMobilControl("left") }}></div> */}

          {/* <Newgame
                      displayNewgame={true}
                      restartgame={props.restartgame}
                      setDisplayMenu={props.setDisplayPopupFinish}
                    /> */}
          
        </div>
      );
  // }
}


  // export default React.memo(MobilControl);
export default MobilControl;