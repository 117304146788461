import React from 'react';

const Popup = (props) => {



  const displayCaracts = (type, image, txt) => {
    if (type === true) {
      return (
        <>
          <div className="itemcaracts">
            <div className={image}></div>
            <div className="txt">{txt}</div>
          </div>
        </>
      );
    }
  }

  if (props.displayPopup === true) {
  return (
        <div className="menu">
          <div className="menucontente">
          <p className="menutitre">Labyrinthe</p>
          <p className="menuniveau">Niveau {props.level}
          <span className="size">({props.levelContenu.horizontal_square}X{props.levelContenu.vertical_square})</span>
          </p>
          <div className="menubtn" onClick={()=>{props.setDisplayPopup(false);
          props.setCanIMove(true);
          }}>Continuer</div>

<div className="boxcaracts">
            {
              (() => {
                if (props.levelContenu.case_melangeur === true) {
                  return (
                    displayCaracts(props.levelContenu.case_melangeur, "case_melangeur", "Reconstruction")
                  )
                }
              })()
            }
            {
              (() => {
                if (props.levelContenu.case_randomtp === true) {
                  return (
                    displayCaracts(props.levelContenu.case_randomtp, "case_randomtp", "Téléportation")
                  )
                }
              })()
            }
            {
              (() => {
                if (props.levelContenu.case_hide === true) {
                  return (
                    displayCaracts(props.levelContenu.case_hide, "case_hide", "Oubli")
                  )
                }
              })()
            }
          </div>
          </div>
        </div>
      );
    }
    else{
      return null;
    }
}


export default React.memo(Popup);
  // export default Menu;