import React from 'react';

const Openmenu = (props) => {



  if (props.displayMenu === false) {
  return (
        <div className="openmenu">
          <div className="openmenubtn" onClick={()=>{props.setDisplayMenu(true)}}>menu</div>
        </div>
      );
    }
    else{
      return null;
    }
}


export default React.memo(Openmenu);
  // export default Openmenu;