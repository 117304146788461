
import React, { useState, useEffect } from 'react';
import './../index.css';
import Board from './Board';
import StartGame from './StartGame';
import ErrorBoundary from './ErrorBoundary'

import MobilControl from './MobilControl'
import Player from './Player'
import Menu from './Menu'
import Popup from './Popup'
import PopupFinish from './PopupFinish'

import Openmenu from './Openmenu'



// https://whereisthemouse.com/create-a-list-component-with-keyboard-navigation-in-react

const Game = () => {

    const correspondanceKeyPressWall = { "ArrowUp": "w_t", "ArrowDown": "w_b", "ArrowRight": "w_r", "ArrowLeft": "w_l" }
    const correspondanceKeyPressWallPeripherique = { "ArrowUp": ["w_r", "w_l"], "ArrowDown": ["w_r", "w_l"], "ArrowRight": ["w_t", "w_b"], "ArrowLeft": ["w_t", "w_b"] }
    const correspondanceKeyPressU = { "ArrowUp": "u_t", "ArrowDown": "u_b", "ArrowRight": "u_r", "ArrowLeft": "u_l" }
    const correspondanceKeyPressUPeripherique = { "w_t": "u_t", "w_b": "u_b", "w_r": "u_r", "w_l": "u_l" }

    const visionProfonde = 3;
    const visonPeripherique = 1; // non utilisé

    const [squares, setSquares] = useState([]);
    const [currentSquare, setCurrentSquare] = useState(0);
    const [styleTop, setStyleTop] = useState("calc(50% - 50px - (100px * 0))");
    const [styleLeft, setStyleLeft] = useState("calc(50% - 50px - (100px * 0))");

    const [gamePositionX, setGamePositionX] = useState("0px");
    const [gamePositionY, setGamePositionY] = useState("0px");

    const [direction, setDirection] = useState("ArrowDown");
    const [classlogo, setClasslogo] = useState("1");

    const [level, setLevel] = useState(1);
    const [displayMenu, setDisplayMenu] = useState(true);


    const [dataMobilControl, setDataMobilControl] = useState(false);
    
    useEffect(() => {

        // console.log(dataMobilControl);
        handleKeyscreen(dataMobilControl);
        setDataMobilControl(false);
        // let bubBuilded = buildLab(buildParenteSquaresProps(true), true);

        // setSquares(bubBuilded);
        // setGamePositionX("0px");
        // setGamePositionY("0px");
        // setCurrentSquare(0)
        // setStyleTop("calc(50% - 50px - (100px * 0))");
        // setStyleLeft("calc(50% - 50px - (100px * 0))");



    }, [dataMobilControl])


    const handleKeyscreen = (event) => {
        if (event === 'ArrowUp' || event === 'ArrowDown' || event === 'ArrowRight' || event === 'ArrowLeft') {
            mouveLab(event);
        }
    };


    const [displayPopup, setDisplayPopup] = useState(false);


    const [displayPopupFinish, setDisplayPopupFinish] = useState(false);
    


    const [canIMove, setCanIMove] = useState(true);

    const [squaresvisible, setSquaresvisible] = useState({});

    const [gameStarted, setGameStarted] = useState(false);


    class levelDescription {
        constructor(
            horizontal_square = 5,
            vertical_square = 8,
            case_melangeur = false,
            nbr_melangeur = 1,
            case_randomtp = false,
            nbr_randomtp = 1,
            case_hide = false,
            nbr_hide = 1,
            case_rebuildlab = false,
        ) {
            this.horizontal_square = horizontal_square;
            this.vertical_square = vertical_square;
            this.case_melangeur = case_melangeur;
            this.nbr_melangeur = nbr_melangeur;
            this.case_randomtp = case_randomtp;
            this.nbr_randomtp = nbr_randomtp;
            this.case_hide = case_hide;
            this.nbr_hide = nbr_hide;
            this.case_rebuildlab = case_rebuildlab;
        }
    };


    // const levelDescription={
    //     horizontal_square: false, 
    //     vertical_square: false, 
    //     case_melangeur: false, reconstruit le labyrinte
    //     case_randomtp: false, teleport player
    //     case_hide: false, cache les cases visiter
    //     case_rebuildlab: false
    // };

    const levelContenu = {
        1: new levelDescription(10, 10, false, 0, false, 0, false, 0, true),
        2: new levelDescription(15, 15, false, 0, false, 0, true, 8, true), // => active case_hide
        3: new levelDescription(20, 25, false, 0, true, 8, true, 8, true), // => active randomtp
        4: new levelDescription(30, 35, true, 12, true, 15, true, 20, true), // => active melangeur
        5: new levelDescription(35, 50, true, 15, true, 30, true, 35, true), // big all
    };


    useEffect(() => {
        let bubBuilded = buildLab(buildParenteSquaresProps(true), true);
        setSquares(bubBuilded);
    }, []);

    useEffect(() => {
        window.addEventListener("keydown", handleKeyPress);
        return () => {
            window.removeEventListener("keydown", handleKeyPress);
        };
    });






    const centerLab = (u) => {
        let centerSquares = [];
        for (let i = 1+u; i <= levelContenu[level].vertical_square - u; i++) {
            for (let j = 1+u; j <= levelContenu[level].horizontal_square - u; j++) {
                centerSquares.push(j - 1 + (i - 1) * levelContenu[level].horizontal_square);
            }
        }
        return centerSquares;
    }

    const buildLab = (originalSquares, reloadCaracts) => {

        // création des groupes de squares ordonnés
        // pour le moment un square par groupe
        let td = {};
        for (const element of originalSquares) {
            td[element.groupe] = [element];
        }

        // on mélange les groupes du lab
        const shuffleObject = (obj) => {
            let keys = Object.keys(obj);
            keys.sort(() => Math.random() - 0.5);
            let shuffledObj = {};
            for (let key of keys) {
                shuffledObj["g" + key] = obj[key];
            }
            return shuffledObj;
        };

        // c'est la liste des groupes du lab
        let shuffleResutl = shuffleObject(td);
        let reductGroupe = reductGroupeLab(shuffleResutl, originalSquares);
        while (Object.keys(reductGroupe).length > 1) {
            reductGroupe = reductGroupeLab(reductGroupe, originalSquares);
        }
        let normaliseShuffleResutl = [];
        for (const [index, element] of reductGroupe.g0.entries()) {
            normaliseShuffleResutl.push(element);
        }

        normaliseShuffleResutl.sort((a, b) => a.valeur - b.valeur);
        // const newnormaliseShuffleResutl =this.regrouperEnPack(normaliseShuffleResutl);

        if (reloadCaracts) {
            normaliseShuffleResutl[0].visited = 1;
        }

        let tmpSquaresvisible = {};
        tmpSquaresvisible[0] = normaliseShuffleResutl[0];
        let defaultDirection = "ArrowDown";
        if (normaliseShuffleResutl[0].w_b === 1) {
            setDirection("ArrowRight");
            defaultDirection = "ArrowRight";
        }
        let tmpCurrentSquare = 0;
        for (const direction of correspondanceKeyPressWallPeripherique[defaultDirection]) {
            if (normaliseShuffleResutl[tmpCurrentSquare][direction] === 0) {
                if (reloadCaracts) {
                    normaliseShuffleResutl[normaliseShuffleResutl[tmpCurrentSquare][correspondanceKeyPressUPeripherique[direction]]].visited = 1;
                }
                tmpSquaresvisible[normaliseShuffleResutl[tmpCurrentSquare][correspondanceKeyPressUPeripherique[direction]]] = normaliseShuffleResutl[normaliseShuffleResutl[tmpCurrentSquare][correspondanceKeyPressUPeripherique[direction]]];
            }
        }
        for (let i = 0; i < visionProfonde; i++) {
            if (normaliseShuffleResutl[tmpCurrentSquare][correspondanceKeyPressWall[defaultDirection]] === 0) {
                if (reloadCaracts) {
                    normaliseShuffleResutl[normaliseShuffleResutl[tmpCurrentSquare][correspondanceKeyPressU[defaultDirection]]].visited = 1;
                }
                tmpCurrentSquare = normaliseShuffleResutl[normaliseShuffleResutl[tmpCurrentSquare][correspondanceKeyPressU[defaultDirection]]].valeur;
                tmpSquaresvisible[tmpCurrentSquare] = normaliseShuffleResutl[tmpCurrentSquare];
            }
            else {
                i = 10;
            }
        }
        if (reloadCaracts) {
            setSquaresvisible(tmpSquaresvisible);
        }
        return normaliseShuffleResutl;
    }


    const reductGroupeLab = (shuffleResutl, originalSquares) => {
        // on mélange le tableau des Directions
        const randArray = (array) => array.sort(() => Math.random() - 0.5);
        const correspondanceUW = { "u_b": "w_b", "u_t": "w_t", "u_r": "w_r", "u_l": "w_l" }
        const correspondanceInverseUW = { "u_b": "w_t", "u_t": "w_b", "u_r": "w_l", "u_l": "w_r" }

        // on boucle sur les groupes du lab
        for (const [key, value] of Object.entries(shuffleResutl)) {
            // on boucle sur les squares du groupe de la boucle

            for (const element of value) {
                if (typeof shuffleResutl[key] !== "undefined") {
                    let randomDirection = randArray(["u_b", "u_t", "u_r", "u_l"]);

                    let n = 0
                    while (n < 3) {
                        if (typeof shuffleResutl[key] !== "undefined") {

                            let testSquareId = element[randomDirection[n]];
                            if (testSquareId != null) {

                                // compareSquares[testSquareId] square a comparer
                                let IdGroupeDeComparaison = originalSquares[testSquareId].groupe;
                                if (IdGroupeDeComparaison !== element.groupe) {

                                    // on push sur element (boucle courante)
                                    if (IdGroupeDeComparaison > element.groupe) {
                                        if (typeof shuffleResutl["g" + IdGroupeDeComparaison] !== "undefined") {
                                            element[correspondanceUW[randomDirection[n]]] = 0;
                                            for (const TmpResutly of shuffleResutl["g" + IdGroupeDeComparaison]) {
                                                if (TmpResutly.valeur === testSquareId) {
                                                    TmpResutly[correspondanceInverseUW[randomDirection[n]]] = 0
                                                }
                                                TmpResutly.groupe = element.groupe;
                                                shuffleResutl[key].push(TmpResutly);
                                            }
                                            delete shuffleResutl["g" + IdGroupeDeComparaison];
                                        }
                                    }
                                    else {
                                        if (typeof shuffleResutl["g" + IdGroupeDeComparaison] !== "undefined") {

                                            element[correspondanceUW[randomDirection[n]]] = 0;

                                            for (const TmpResutly of shuffleResutl["g" + IdGroupeDeComparaison]) {
                                                if (TmpResutly.valeur === testSquareId) {
                                                    TmpResutly[correspondanceInverseUW[randomDirection[n]]] = 0
                                                }
                                            }
                                            for (const TmpResutly of shuffleResutl[key]) {
                                                TmpResutly.groupe = IdGroupeDeComparaison;
                                                shuffleResutl["g" + IdGroupeDeComparaison].push(TmpResutly);
                                            }
                                            delete shuffleResutl[key];
                                        }
                                    }
                                    n = 10;
                                }
                            }
                            n++
                        }
                    }
                }
            }
        }

        return shuffleResutl;

    }

    const getRandomInt = (max) => {
        return Math.floor(Math.random() * max);
    }

    const buildParenteSquaresProps = (reloadCaracts) => {

        const randorm_sortie = getRandomInt(4) + 1;

        let id_sortie = levelContenu[level].horizontal_square * levelContenu[level].vertical_square - 1;
        let direction_sortie = "bottomright";
        switch (randorm_sortie) {
            case 1:
                //haut droit
                id_sortie = levelContenu[level].horizontal_square - 1;
                direction_sortie = "topright";
                break;
            case 2:
                // bas gauche
                id_sortie = levelContenu[level].horizontal_square * (levelContenu[level].vertical_square - 1);
                direction_sortie = "bottomleft";
                break;
            case 3:
                // bas droit
                id_sortie = levelContenu[level].horizontal_square * levelContenu[level].vertical_square - 1;
                direction_sortie = "bottomright";
                break;
            case 4:
                id_sortie = levelContenu[level].horizontal_square * levelContenu[level].vertical_square - 1;
                direction_sortie = "bottomright";
                break;
            default:
                id_sortie = levelContenu[level].horizontal_square * levelContenu[level].vertical_square - 1;
                direction_sortie = "bottomright";
        }


        let td = [];
        for (let v = 1; v <= levelContenu[level].vertical_square; v++) {
            for (let h = 1; h <= levelContenu[level].horizontal_square; h++) {
                let idSquare = h - 1 + (v - 1) * levelContenu[level].horizontal_square;
                let tmpSquare = {};
                tmpSquare.w_r = 1;
                tmpSquare.w_l = 1;
                tmpSquare.w_t = 1;
                tmpSquare.w_b = 1;
                if (idSquare === id_sortie) {
                    tmpSquare.caracts = { end: direction_sortie };
                }
                else {
                    tmpSquare.caracts = {};
                }

                tmpSquare.fa = getRandomInt(3) + 1
                tmpSquare.visited = 0;
                tmpSquare.groupe = idSquare;
                tmpSquare.valeur = idSquare;

                if (idSquare % (levelContenu[level].horizontal_square) === 0) {
                    tmpSquare.u_r = idSquare + 1;
                    tmpSquare.u_l = null;
                    tmpSquare.w_l = 3;
                }
                else if (idSquare % (levelContenu[level].horizontal_square) === levelContenu[level].horizontal_square - 1) {
                    tmpSquare.u_l = idSquare - 1;
                    tmpSquare.u_r = null;
                    tmpSquare.w_r = 3;
                }
                else {
                    tmpSquare.u_r = idSquare + 1;
                    tmpSquare.u_l = idSquare - 1;
                }

                if (idSquare > levelContenu[level].horizontal_square - 1) {
                    tmpSquare.u_t = idSquare - levelContenu[level].horizontal_square;
                }
                else {
                    tmpSquare.u_t = null;
                    tmpSquare.w_t = 3;
                }

                if (v < levelContenu[level].vertical_square) {
                    tmpSquare.u_b = idSquare + levelContenu[level].horizontal_square;
                }
                else {
                    tmpSquare.u_b = null;
                    tmpSquare.w_b = 3;
                }

                td.push(tmpSquare);
            }
        }

        td[0].caracts.open = 1;

        const centerSquares = centerLab(2);
        if (reloadCaracts) {
            if (levelContenu[level].case_melangeur === true) {
                for (let i = 0; i < levelContenu[level].nbr_melangeur; i++) {
                    if (centerSquares.length > 0) {
                        let rand = Math.floor(Math.random() * centerSquares.length);
                        let randomSquare = centerSquares[rand];
                        td[randomSquare].caracts.melangeur = true;
                        centerSquares.splice(rand, 1);
                    }
                }
            }

            if (levelContenu[level].case_hide === true) {
                for (let i = 0; i < levelContenu[level].nbr_hide; i++) {
                    if (centerSquares.length > 0) {
                        let rand = Math.floor(Math.random() * centerSquares.length);
                        let randomSquare = centerSquares[rand];
                        td[randomSquare].caracts.hide = true;
                        centerSquares.splice(rand, 1);
                    }
                }
            }

            if (levelContenu[level].case_randomtp === true) {
                for (let i = 0; i < levelContenu[level].nbr_randomtp; i++) {
                    if (centerSquares.length > 0) {
                        let rand = Math.floor(Math.random() * centerSquares.length);
                        let randomSquare = centerSquares[rand];
                        td[randomSquare].caracts.randomtp = true;
                        centerSquares.splice(rand, 1);
                    }
                }
            }
        }

        return td;
    }

    const restartgame = () => {

        let bubBuilded = buildLab(buildParenteSquaresProps(true), true);
        setSquares(bubBuilded);
        setGamePositionX("0px");
        setGamePositionY("0px");
        setCurrentSquare(0)
        setStyleTop("calc(50% - 50px - (100px * 0))");
        setStyleLeft("calc(50% - 50px - (100px * 0))");
        setLevel(1);
        setCanIMove(true);
        setGameStarted(false);
    }

    const handleKeyPress = (event) => {
        if (event.key === 'ArrowUp' || event.key === 'ArrowDown' || event.key === 'ArrowRight' || event.key === 'ArrowLeft') {
            mouveLab(event.key);
        }
    };

    const mouveLab = (KeyPress) => {
        if (canIMove === true) {
            if (squares[currentSquare][correspondanceKeyPressWall[KeyPress]] === 0) {

                let newCurrentSquare = squares[currentSquare][correspondanceKeyPressU[KeyPress]];
                if (classlogo === "2") {
                    setDirection(KeyPress);
                    setClasslogo("1");
                }
                else {
                    setDirection(KeyPress);
                    setClasslogo("2");
                }
                setCurrentSquare((prevCurrentSquare) => {
                    return (newCurrentSquare);
                });
                setStyleLeft((prevStyleLeft) => {
                    return ("calc(50% - 50px - (99px * " + (newCurrentSquare) % (levelContenu[level].horizontal_square) + "))");
                });
                setStyleTop((prevStyleTop) => {
                    return ("calc(50% - 50px - (99px * " + Math.floor((((newCurrentSquare)) / levelContenu[level].horizontal_square)) + "))");
                });

                setGamePositionX((prevStyleLeft) => {
                    return ("" + (newCurrentSquare) % (levelContenu[level].horizontal_square) * 25 * -1 + "px");
                });
                setGamePositionY((prevStyleTop) => {
                    return ("" + Math.floor((((newCurrentSquare)) / levelContenu[level].horizontal_square)) * 25 * -1 + "px");
                });


                let tmpCurrentSquare = newCurrentSquare;
                let tmpSquaresvisible = {};
                tmpSquaresvisible[newCurrentSquare] = squares[tmpCurrentSquare];

                let tmpUpdateSquare = [...squares];
                tmpUpdateSquare[tmpCurrentSquare].visited = 1;


                for (const direction of correspondanceKeyPressWallPeripherique[KeyPress]) {
                    if (squares[tmpCurrentSquare][direction] === 0) {

                        tmpUpdateSquare[squares[tmpCurrentSquare][correspondanceKeyPressUPeripherique[direction]]].visited = 1;
                        tmpSquaresvisible[squares[tmpCurrentSquare][correspondanceKeyPressUPeripherique[direction]]] = squares[squares[tmpCurrentSquare][correspondanceKeyPressUPeripherique[direction]]];
                    }
                }
        
                for (let i = 0; i < visionProfonde; i++) {
                    if (squares[tmpCurrentSquare][correspondanceKeyPressWall[KeyPress]] === 0) {
                        tmpUpdateSquare[squares[tmpCurrentSquare][correspondanceKeyPressU[KeyPress]]].visited = 1;
                        tmpCurrentSquare = squares[squares[tmpCurrentSquare][correspondanceKeyPressU[KeyPress]]].valeur;
                        tmpSquaresvisible[tmpCurrentSquare] = squares[tmpCurrentSquare];
                    }
                    else {
                        i = 10;
                    }
                }



                for (const [keySquaresvisible, valueSquaresvisible] of Object.entries(squaresvisible)) {
                    if (!tmpSquaresvisible.hasOwnProperty(keySquaresvisible)) {
                        tmpUpdateSquare[keySquaresvisible].visited = 2;
                    }
                }


                setSquares(tmpUpdateSquare);


                setSquaresvisible(tmpSquaresvisible);

                applyCaract(squares[newCurrentSquare], KeyPress);
            }

            if(gameStarted === false){
                setGameStarted(true);
            }
        }
    };

    const applyCaract = (newCurrentSquare, KeyPress) => {
        for (const [keyCaracts, valueCaracts] of Object.entries(newCurrentSquare.caracts)) {
            if (keyCaracts === "end") {
                setCanIMove(false);
                levelUp();
            }
            if (keyCaracts === "melangeur") {
                let bubBuilded = buildLab(buildParenteSquaresProps(false), false);
                for (var i = 0; i < bubBuilded.length; i++) {
                    bubBuilded[i].visited = squares[i].visited;
                    bubBuilded[i].caracts = squares[i].caracts;
                }
                setSquares(bubBuilded);
            }

            if (keyCaracts === "hide") {
                const copySquares = [...squares];
                for (const element of copySquares) {
                    element.visited = 0;
                }

                let tmpCurrentSquare = copySquares[copySquares[currentSquare][correspondanceKeyPressU[KeyPress]]].valeur;

                let tmpSquaresvisible = {};
                tmpSquaresvisible[newCurrentSquare] = squares[tmpCurrentSquare];

                let tmpUpdateSquare = [...squares];
                tmpUpdateSquare[tmpCurrentSquare].visited = 1;

                for (const direction of correspondanceKeyPressWallPeripherique[KeyPress]) {
                    if (squares[tmpCurrentSquare][direction] === 0) {
                        tmpUpdateSquare[squares[tmpCurrentSquare][correspondanceKeyPressUPeripherique[direction]]].visited = 1;
                    }
                }
                for (let i = 0; i < visionProfonde; i++) {
                    if (squares[tmpCurrentSquare][correspondanceKeyPressWall[KeyPress]] === 0) {
                        tmpUpdateSquare[squares[tmpCurrentSquare][correspondanceKeyPressU[KeyPress]]].visited = 1;
                        tmpCurrentSquare = squares[squares[tmpCurrentSquare][correspondanceKeyPressU[KeyPress]]].valeur;
                    }
                    else {
                        i = 10;
                    }
                }
                setSquares(copySquares);
            }


            if (keyCaracts === "randomtp" && valueCaracts == true) {

                const centerSquares = centerLab(1);
                let tmpUpdateSquare = [...squares];
                const keyOncenterSquaresofCurrentSquare = centerSquares.findIndex(element => element == currentSquare);
                if(keyOncenterSquaresofCurrentSquare !== -1){
                    centerSquares.splice(keyOncenterSquaresofCurrentSquare, 1);
                }
                let listeRm = []
                let centerSquareslength = centerSquares.length
                for (var i = 0; i < centerSquareslength; i++) {
                    if (Object.keys(tmpUpdateSquare[centerSquares[i]].caracts).length  > 0) {
                        listeRm.push(centerSquares[i]);
                    }
                }
                for (let i = 0; i < listeRm.length; i++) {
                    let rm_item_key = centerSquares.findIndex(element => element == listeRm[i]);

                    if(rm_item_key !== -1){
                        centerSquares.splice(rm_item_key, 1);
                    }
                }
                if (centerSquares.length > 0) {
                    let rand = Math.floor(Math.random() * centerSquares.length);
                    setCurrentSquare((prevCurrentSquare) => {
                        return (centerSquares[rand]);
                    });
                    setStyleLeft((prevStyleLeft) => {
                        return ("calc(50% - 50px - (99px * " + (centerSquares[rand]) % (levelContenu[level].horizontal_square) + "))");
                    });
                    setStyleTop((prevStyleTop) => {
                        return ("calc(50% - 50px - (99px * " + Math.floor((((centerSquares[rand])) / levelContenu[level].horizontal_square)) + "))");
                    });
    
                    setGamePositionX((prevStyleLeft) => {
                        return ("" + (centerSquares[rand]) % (levelContenu[level].horizontal_square) * 25 * -1 + "px");
                    });
                    setGamePositionY((prevStyleTop) => {
                        return ("" + Math.floor((((centerSquares[rand])) / levelContenu[level].horizontal_square)) * 25 * -1 + "px");
                    });
                    let tmpSquaresvisible = {};
                    // set Squaresvisible on current
                    tmpSquaresvisible[centerSquares[rand]] = squares[centerSquares[rand]];
                    // clear visible 1 sur setSquaresvisible
                    for (const [keySquaresvisible, valueSquaresvisible] of Object.entries(squaresvisible)) {
                            tmpUpdateSquare[keySquaresvisible].visited = 2;
                    }
                    for (const element of tmpUpdateSquare) {
                        if(element.visited == 1){
                            tmpUpdateSquare[element.valeur].visited = 2;
                        }
                    }
                    tmpUpdateSquare[centerSquares[rand]].visited = 1;
                    let tmpCurrentSquare = tmpUpdateSquare[centerSquares[rand]].valeur;
                    tmpUpdateSquare[squares[currentSquare][correspondanceKeyPressU[KeyPress]]].caracts.randomtp = false;

                    for (const direction of correspondanceKeyPressWallPeripherique[KeyPress]) {
                        if (squares[tmpCurrentSquare][direction] === 0) {
                            tmpUpdateSquare[squares[tmpCurrentSquare][correspondanceKeyPressUPeripherique[direction]]].visited = 1;
                            tmpSquaresvisible[squares[tmpCurrentSquare][correspondanceKeyPressUPeripherique[direction]]] = squares[squares[tmpCurrentSquare][correspondanceKeyPressUPeripherique[direction]]];
                        }
                    }
                    for (let i = 0; i < visionProfonde; i++) {
                        if (squares[tmpCurrentSquare][correspondanceKeyPressWall[KeyPress]] === 0) {
                            tmpUpdateSquare[squares[tmpCurrentSquare][correspondanceKeyPressU[KeyPress]]].visited = 1;
                            tmpCurrentSquare = squares[squares[tmpCurrentSquare][correspondanceKeyPressU[KeyPress]]].valeur;
                            tmpSquaresvisible[tmpCurrentSquare] = squares[tmpCurrentSquare];
                        }
                        else {
                            i = 10;
                        }
                    }
                    setSquares(tmpUpdateSquare);
                    setSquaresvisible(tmpSquaresvisible);
                }
            }
        }
    }


    useEffect(() => {
        let bubBuilded = buildLab(buildParenteSquaresProps(true), true);

        setSquares(bubBuilded);
        setGamePositionX("0px");
        setGamePositionY("0px");
        setCurrentSquare(0)
        setStyleTop("calc(50% - 50px - (100px * 0))");
        setStyleLeft("calc(50% - 50px - (100px * 0))");



    }, [level])

    const levelUp = () => {
        setTimeout(() => {
            if(levelContenu.hasOwnProperty(level+ 1)){
            setLevel((prevLevel) => {
                return (prevLevel + 1);
            });
            setDisplayPopup(true);
        }
        else{
            setDisplayPopupFinish(true);
        }
        }, 400)
    }

    return (
        <div className="game" style={{ backgroundPositionX: gamePositionX, backgroundPositionY: gamePositionY }}>



            <MobilControl
            setDataMobilControl={setDataMobilControl}
            />

            <Openmenu
                displayMenu={displayMenu}
                setDisplayMenu={setDisplayMenu}
            />
            <Menu
                displayMenu={displayMenu}
                level={level}
                setDisplayMenu={setDisplayMenu}
                restartgame={restartgame}
                gameStarted={gameStarted}
                levelContenu={levelContenu[level]}
            />

            <Popup
                displayPopup={displayPopup}
                level={level}
                setDisplayPopup={setDisplayPopup}
                setCanIMove={setCanIMove}
                levelContenu={levelContenu[level]}
            />

            <PopupFinish
            setDisplayMenu={setDisplayMenu}
                restartgame={restartgame}
                setDisplayPopupFinish={setDisplayPopupFinish}
                displayPopupFinish={displayPopupFinish}
            />  

            {/* <ErrorBoundary> */}



            {
                (() => {
                    if (squares.length === levelContenu[level].horizontal_square * levelContenu[level].vertical_square) {
                        return (

                            <>
                                <Player direction={direction}
                                    classlogo={classlogo} />
                                <div className="game-board"
                                    style={{ top: styleTop, left: styleLeft }}>
                                    <Board
                                        squares={squares}
                                        horizontal_square={levelContenu[level].horizontal_square}
                                        vertical_square={levelContenu[level].vertical_square}
                                    />
                                </div>
                            </>


                        )
                    }
                })()
            }


            {/* </ErrorBoundary> */}


        </div>
    );


}
export default React.memo(Game);
// export default Game;
