// exemple de function component
// import react non obligatoire mais ici on a besoin de la méthode memo => création d'un PureComponement sur une function component
import React from 'react';





const Square = (props) => {

  let caracts = "";
  // console.log("U");
  // console.log(Object.keys(props.caracts).length);
  // console.log(props.caracts.constructor);
  if(Object.keys(props.caracts).length !== 0){
    // console.log("props.caracts");
    // console.log(props.caracts);
    for (const [keycaracts, valuecaracts] of Object.entries(props.caracts)) {
      // console.log("X");
      // console.log(keycaracts);
      caracts = " "+keycaracts + valuecaracts;
    }
// console.log(caracts);
  }
  
  // console.log("caracts");
  // console.log('display Square');
    return (
        <div className={`square w_r${props.w_r} w_l${props.w_l} w_t${props.w_t} w_b${props.w_b} fa fa${props.fa}  visited${props.visited}${caracts}` }>
          <span className="w ww_l"></span>
          <span className="w ww_t"></span>
          <span className="w ww_b"></span>
          <span className="w ww_r"></span>
          {/* {props.valeur} */}
        </div>
      );

}

// export default Square;
export default React.memo(Square);